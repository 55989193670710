import { myget, mypost } from './index'

//获取验证码
export const getCaptcha = () => myget('/users/captche?time' + Date.now())
// 获取用户列表
export const getUserlist = (data) => myget('/users/list', data)
//根据token获取用户信息
export const getUserInfo = () => myget('/users/getUserInfo')
export const getRemarks = () => myget('/users/getRemarks')
export const getUserInfoById = (id) => myget('/users/getUserInfoById/' + id)
///用户登录
export const login = (data) => mypost('/users/login', data)
// 用户注册
// export const register = (data) => post('/register/cellphone', data)

export const getUserlistAll = (data) => {
    return myget('/users/listAll', data)
}
// 修改用户
export const UpdateUser = (data) => {
    return mypost('/users/update', data)
}
// 模糊查询用户
export const SearchUser = (data) => {
    return myget('/users/findLike', data)
}
// 添加用户
export const addUser = (data) => {
    return mypost('/users/add', data)
} // 批量添加
export const addUserList = (data) => {
    return mypost('/users/updateList', data)
}
// 删除用户
export const delUser = (data) => {
    return mypost('/users/del', data)
}
// 修改密码
export const updatePwd = (data) => {
    return mypost('/users/updatePwd', data)
}
// 获取用户银行卡信息
export const getUserBank = (data) => {
    return mypost('/user/get_userBank', data)
}
// 更新用户银行卡信息
export const updateUserBank = (data) => {
    return mypost('/user/update_userBank', data)
}
// 获取用户提现
export const getUserCash = (data) => {
    return mypost('/user/get_userCash', data)
}


// 退款按钮
export const getUserRefund = (data) => {
    return mypost('/user/cash/refund', data)
}


// 获取新用户提现
export const getUserCashNew = (data) => {
    return mypost('/user/get_userCashTemp', data)
}









// 打款银行卡
export const debitCard = (data) => {
    return mypost('/user/debit_card', data)
}
// 获取用户详情
export const getUserDetails = (data) => {
    return mypost('/user/details', data)
}
// 获取用户列表
export const getUserList = (data) => {
    return mypost('/user/list', data)
}

//
export const getComplaintList = (data) => {
    return mypost('/complaint/list', data)
}

export const pass = (data) => {
    return mypost('/complaint/pass', data)
}

// 修改用户
export const updateUser = (data) => {
    return mypost('/user/update', data)
}
// 添加机器人
export const addRobot = (data) => {
    return mypost('/user/Robot/add', data)
}
// 更新用户手机号
export const updateUserPhone = (data) => {
    return mypost('/user/phone/update', data)
}

// 删除用户提现
export const deleteUserCash = (data) => {
    return mypost('/user/cash/delete', data)
}
// 授权支付
export const authorizedUserPayment = (data) => {
    return mypost('/user/authorized/payment', data)
}

// 获取聚合商户余额
export const getPuShangMerchantBalance = () => myget('/user/juhe/balance')

// 获取谦硕余额
export const getFuLuMerchantBalance = () => myget('/user/fulu/balance')

// 阿里云API余量数据
// export const alibabaCloudDescribeApiMetering = () => myget('/user/alibaba/margin')

// 获取海威余额
export const getHaiWeiBalance = () => myget('/user/haiwei/balance')

// 获取洋芋余额
export const getPhoneBalance = () => myget('/user/yangyu/balance')

// 获取聚推客余额
export const getJuTuiKeBalance = () => myget('/user/jutuike/balance')

// 获取影划算余额
export const getYingHuaSuanBalance = () => myget('/user/yinghuasuan/balance')

// 获取停车场余额
export const getTingCheChangBalance = () => myget('/user/carpark/balance')






// 获取中石油绑定列表
export const getUserBindPetroChinaList = (data) => {
    return mypost('/user/bind_PetroChina/list', data)
}
// 更改中石油绑定
export const updateUserBindPetroChina = (data) => {
    return mypost('/user/bind_PetroChina/update', data)
}
// 获取中石化绑定列表
export const getUserBindSinopecList = (data) => {
    return mypost('/user/bind_Sinopec/list', data)
}
// 更改中石化绑定
export const updateUserBindSinopec = (data) => {
    return mypost('/user/bind_Sinopec/update', data)
}